// ----
//  Core Configuration Variable Overrides
// ----
$base-font-size: 14px;
$default-web-font: 'Ubuntu';

$primary: #7fc241;


// ----
//  Import Initial Configuration Files
// ----
@import '~@appbuckets/react-bucket/src/styles/config-core';
@import '~@appbuckets/react-bucket/src/styles/_core/functions/all';


// ----
//  Color Variables Override
// ----


// ----
//  Colors Import
// ----
@import '~@appbuckets/react-bucket/src/styles/_core/colors';
@import '~@appbuckets/react-bucket/src/styles/_core/typography';
@import '~@appbuckets/react-bucket/src/styles/_core/variables';


// ----
//  Variable Override per Elements
// ----
$button-text-transform: uppercase;

$header-bottom-margin: 2em;
$header-base-font-size: 1.5em;

$panel-vertical-padding: 1.75em;
$panel-horizontal-padding: 1.5em;

$table-row-gutter: 1.5em;
