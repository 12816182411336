.virtualized.table .virtualized.body {
  > .loading-row,
  > .error-row,
  > .no-content-row {
    justify-content: center;
    border-bottom: none;
  }

  > .error-row {
    padding: 2em 0;

    > .cell > .message {
      margin: 0;
    }
  }
}


.color.field .item .content {
  height: $computed-input-height;
  min-height: $computed-input-height;
}


.panel > .head {
  font-size: 1em;

  > .header {
    font-size: $h4-font-size;
  }
}

.section > .content > .item > .content {
  min-height: 2em;
}
