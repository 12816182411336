/**
 * Project Yard4
 *
 * _BaseLayout @ src/assets/styles/_base-layout.scss
 *
 * Defined at 17 giu 2020
 * Copyright Marco Cavanna • 2020
 *
 * ---
 * Base Page Layout
 *
 */

// ----
//  Set the View Content Style
// ----
.view-content {
  @include on-screen('desktop') {
    padding: $layout-content-padding / 2 $layout-content-padding 0;
  }
}


// ----
//  Page Based Style
// ----
body.signup {
  background-color: $white;
}
